<template>
  <div id="app">
    <q-layout view="hHh LpR lFf">

      <q-header elevated class="text-white "
        style="background-image: linear-gradient(to right, #0aa0fe 0%, #495aff 100%);color:white;border-radius: 0px"
        height-hint="98">
        <!--        <q-bar class="q-electron-drag text-black"-->
        <!--               style="background: rgba(255, 255, 255, .2);">-->
        <!--          &lt;!&ndash;          <q-icon name="laptop_chromebook"/>&ndash;&gt;-->
        <!--          <div>L4D2Love求生之路对战平台</div>-->
        <!--          <q-space/>-->
        <!--        </q-bar>-->
        <q-toolbar>
          <!--          <q-btn icon="eco" @click="toBBS()" flat text-color="primary" label="社区">-->
          <!--            <q-tooltip transition-show="flip-right"-->
          <!--                       transition-hide="flip-left" class="text-body2" :offset="[10, 10]">-->
          <!--              社区受理任何举报,如果你遭遇不公平待遇请截图举报至社区!-->
          <!--            </q-tooltip>-->
          <!--          </q-btn>-->
          <!--          <q-btn icon="video_call" @click="toLive()" flat text-color="red" label="直播">-->
          <!--            <q-tooltip transition-show="flip-right"-->
          <!--                       transition-hide="flip-left" class="text-body2" :offset="[10, 10]">-->
          <!--              L4D2Love直播间,有想直播的联系群主-->
          <!--            </q-tooltip>-->
          <!--          </q-btn>-->
          <q-btn text-color="white" @click="toRouter('/index')" flat label="L4D2Love">
            <q-tooltip transition-show="flip-right" transition-hide="flip-left" class="text-body2" :offset="[10, 10]">
              L4D2Love
            </q-tooltip>
          </q-btn>
          <q-btn @click="toRouter('/index')" text-color="white" flat>
            <!--            <q-avatar rounded size="48px">-->
            <!--              <img src="@/assets/icon/home.png">-->
            <!--            </q-avatar>-->
            <div class="row items-center no-wrap">
              <q-avatar style="margin-right: 10px" rounded size="18px">
                <img src="@/assets/icon/game.png">
              </q-avatar>
              <div class="text-center">
                首页
              </div>
            </div>
          </q-btn>
          <div v-show="null != player.steamId">
            <q-btn @click="toRouter('/rank')" text-color="white" flat label="">
              <q-tooltip transition-show="flip-right" transition-hide="flip-left" class="text-body2" :offset="[10, 10]">
                L4D2Love 天花板玩家
              </q-tooltip>
              <div class="row items-center no-wrap">
                <q-avatar style="margin-right: 10px" rounded size="18px">
                  <img src="@/assets/icon/ranking.png">
                </q-avatar>
                <div class="text-center">
                  排行榜
                </div>
              </div>
            </q-btn>
            <q-btn @click="toRouter('/ban')" text-color="white" flat label="">
              <q-tooltip transition-show="flip-right" transition-hide="flip-left" class="text-body2" :offset="[10, 10]">
                记录中途退出,作弊,无素质谩骂被封禁的玩家
              </q-tooltip>
              <div class="row items-center no-wrap">
                <q-avatar style="margin-right: 10px" rounded size="18px">
                  <img src="@/assets/icon/forbidden.png">
                </q-avatar>
                <div class="text-center">
                  封禁列表
                </div>
              </div>
            </q-btn>
            <q-btn @click="toRouter('/game')" text-color="white" flat label="">
              <q-tooltip transition-show="flip-right" transition-hide="flip-left" class="text-body2" :offset="[10, 10]">
                你最近10场的游戏对局数据
              </q-tooltip>
              <div class="row items-center no-wrap">
                <q-avatar style="margin-right: 10px" rounded size="18px">
                  <img src="@/assets/icon/rec.png">
                </q-avatar>
                <div class="text-center">
                  对局记录
                </div>
              </div>
            </q-btn>
            <!--            <q-btn @click="toRouter('/watch')" flat label="观战游戏"/>-->
            <q-btn v-if="2 == player.roleId" @click="toRouter('/management')" text-color="white" flat label="后台管理" />
          </div>
          <q-space />
          <div v-if="null != player.steamId" class="q-gutter-sm row items-center no-wrap">
            <!--            <q-btn dense flat color="black" icon="video_call" text-color="white" v-if="$q.screen.gt.sm">-->
            <!--              <q-tooltip>录像</q-tooltip>-->
            <!--            </q-btn>-->
            <q-btn @click="toggleDarkMode" dense flat color="black" icon="apps" text-color="white"
              v-if="$q.screen.gt.sm">
              <q-tooltip>切换白天/夜间模式</q-tooltip>
            </q-btn>
            <q-btn dense flat color="black" text-color="white" icon="notifications">
              <!--              <q-badge color="red" text-color="white" floating>-->
              <!--                2-->
              <!--              </q-badge>-->
              <q-tooltip>通知</q-tooltip>
            </q-btn>

            <q-btn flat>
              <q-avatar size="26px">
                <img :src="player.avatarFull">
                <q-menu>
                  <q-list dense style="min-width: 100px">
                    <q-item @click="uploadPlayer" clickable v-close-popup>
                      <q-item-section>从Steam更新资料</q-item-section>
                    </q-item>
                    <q-separator />
                    <q-item @click="loginOut" clickable v-close-popup>
                      <q-item-section>退出登录</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-avatar>
              <q-tooltip>{{ player.personaName }}</q-tooltip>
            </q-btn>
          </div>

          <div v-if="null == player.steamId" class="q-gutter-sm row items-center no-wrap">
            <!--            <q-btn @click="login" color="white" text-color="black" label="登录系统" v-if="$q.screen.gt.sm">-->
            <!--              <q-tooltip>登录系统</q-tooltip>-->
            <!--            </q-btn>-->
          </div>
        </q-toolbar>
      </q-header>
      <q-page-container>
        <router-view v-slot="{ Component }">
          <keep-alive>
            <component class="view" :is="Component" />
          </keep-alive>
        </router-view>
      </q-page-container>

      <!-- <q-footer style="background: rgba(255, 255, 255, .2);height: 40px" class="">
        <q-toolbar
          style="color: black; background: rgba(110, 110, 110, 0.2); display: flex; align-items: center; justify-content: center;">
          <span>{{ footerNotify }}</span>
        </q-toolbar>
      </q-footer> -->

    </q-layout>

    <q-dialog v-model="checkModel" persistent transition-show="flip-down" transition-hide="flip-up">
      <q-card class="bg-negative text-white">
        <q-bar>
          <div>⚠️ 账号检测</div>

          <q-space />

          <q-btn dense flat icon="close" v-close-popup>
            <q-tooltip class="bg-white text-primary">关闭</q-tooltip>
          </q-btn>
        </q-bar>

        <q-card-section>
          <div class="text-h6">你无法开始匹配,当前账号不具备匹配资格,如果您未公开Steam资料请公开后刷新页面重试!</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          公开Steam资料后通常需要等待一段时间数据才会更新
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="rankModel" persistent transition-show="flip-down" transition-hide="flip-up">
      <q-card style="background-image: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%);" class="text-white">
        <q-bar style="background: #00000070 !important;">
          <div>🎉 赛季重制</div>

          <q-space />

          <q-btn dense flat icon="close" v-close-popup>
            <q-tooltip class="bg-white text-primary">关闭</q-tooltip>
          </q-btn>
        </q-bar>

        <q-card-section horizontal>
          <q-card-section class="col-5 flex flex-center">
            <q-img :src="player.level.levelImage" spinner-color="white" fit="fill"
              style="height: 120px; max-width: 110px" />

          </q-card-section>
          <q-card-section class="q-pt-xs">
            <div class="text-overline">New Season</div>
            <div class="text-h6 q-mt-sm q-mb-xs text-orange-9">{{ player.level.levelName }}</div>
            <div class="text-white" style="font-size: 18px">
              L4D2Love 进入第一赛季,根据您的对局信息,您获得 <span class="text-red">{{ player.rankScore }}</span> 排位分!
            </div>
          </q-card-section>
        </q-card-section>

        <q-separator />

        <q-card-section horizontal>
          <q-card-section>
            当前消息框会在您参与一场对局后不再显示
          </q-card-section>

        </q-card-section>
        <!--        <div class="row" style="margin:0px auto">-->
        <!--          {{ player.level.levelDescription }}-->
        <!--        </div>-->
      </q-card>
    </q-dialog>


    <q-dialog v-model="bindModel" persistent transition-show="flip-down" transition-hide="flip-up">
      <q-card style="background-image: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%);" class="text-white">
        <q-bar style="background: #00000070 !important;">
          <div>😊请绑定账号</div>
          <q-space />
          <q-btn dense flat icon="close" v-close-popup>
            <q-tooltip class="bg-white text-primary">关闭</q-tooltip>
          </q-btn>
        </q-bar>

        <q-card-section horizontal>
          <q-card-section>
            <q-form class="q-gutter-md">
              <q-input dark color="white" style="border-bottom: 1px solid white;" v-model="bindForm.username"
                label-color="white" label="账号:" />
              <q-input v-model="bindForm.password" dark style="border-bottom: 1px solid white;" type="password"
                label-color="white" color="white" label="密码:" />
              <q-input v-model="bindForm.confirmPass" dark style="border-bottom: 1px solid white;" type="password"
                label-color="white" color="white" label="确认密码:" />
              <q-banner dense style="background-color: rgba(255,255,255,0.1)" rounded size="xl" class="">
                为了方便登陆,设置个账号密码吧,之后不用通过Steam登陆
              </q-banner>
              <div>
                <q-btn size="md" text-color="white" style="width: 100%;margin-top: 1%" label="保存 (Save)"
                  @click="bindAccount" color="primary" />
              </div>
            </q-form>
          </q-card-section>

        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
// 初始化ws
import { loadUser } from "@/api/main";
import { getToken, removeToken } from "@/util/auth";
import { Dialog, Notify } from "quasar";
import { uploadProfile, bindAccount } from "@/api/player";
import { notifyAudio } from "@/util/NotifyAudio"
import Getters from "@/store/getters"
import main from "@/main";


export default {
  name: "Main",
  data() {
    return {
      checkModel: false,
      rankModel: false,
      bindModel: false,
      dark: false,
      footerNotify: '❤️ L4d2Love 竞赛匹配平台',
      hidden: true,
      player: {},
      bindForm: {}
    }
  },
  created() {
  },
  mounted() {
    this.init();
    // 在组件挂载时检查本地存储中的设置并应用
    const darkModeSetting = localStorage.getItem('darkMode');
    if (darkModeSetting !== null) {
      this.$q.dark.set(darkModeSetting === 'true');
    }
  },
  methods: {
    init() {
      let token = getToken();
      if (null === token || undefined === token) {
        this.$router.replace({
          path: '/login'
        })
        return;
      }
      let uri = process.env.VUE_APP_WEBSOCKET + '?token=' + getToken();
      this.$connect(uri)
      // this.$options.sockets.onmessage = ((res) => {
      //   this.messageHandle(res)
      // })
      loadUser().then((res) => {
        this.player = res.data.extend;
        if (this.player.username == null) {
          this.bindModel = true;
        }
      })
      this.$router.replace({
        path: '/index'
      })
      // this.$options.sockets.onclose = ((e)=>{
      // })
      // let uri = "ws://localhost:1000?token=" + token
      // setTimeout((s) => {
      //   this.$connect(uri)
      //   this.$options.sockets.onmessage = ((res) => {
      //     this.messageHandle(res)
      //   })
      // }, 1000)
    },
    toggleDarkMode() {
      // 切换夜间模式
      this.$q.dark.toggle();

      // 将用户的选择存储到本地存储中
      localStorage.setItem('darkMode', this.$q.dark.isActive);
    },
    bindAccount() {
      bindAccount({
        username: this.bindForm.username,
        password: this.bindForm.password,
        confirmPass: this.bindForm.confirmPass
      }).then((res) => {
        if (res.data.code != 200) {
          Notify.create({
            caption: res.data.msg,
            type: 'warning',
            message: '失败',
            position: 'center',
            progress: true,
          })
          return
        }
        Dialog.create({
          title: '成功',
          message: '现在你可以通过账号密码登陆',
          cancel: false,
          persistent: true
        }).onOk(() => {
          location.reload();
        })
      })
    },
    toRouter(path) {
      this.$router.push({
        path: path
      })
    },
    messageHandle(e) {
      
      this.hidden = false;
      if (e.data === "pong") {
        return;
      }
      if (e.data === "ping") {
        //this.$socket.send("pong");
        return;
      }
      let message = JSON.parse(e.data)
      switch (message.type) {
        case 10000:
          notifyAudio();
          Notify.create({
            caption: "Notify",
            color: 'white',
            textColor: 'black',
            message: message.msg,
            position: 'bottom',
            progress: true,
            timeout: 1000 * 5
          })
          break;
      }

    },
    login() {
      this.$disconnect();
      this.$router.push({
        path: '/login'
      })
    },
    toBBS() {
      window.open('http://bbs.l4d2love.com/')
    },
    toLive() {
      window.open('https://live.l4d2.app/')
    },
    loginOut() {
      if (null == this.player.steamId) {
        return
      }
      Dialog.create({
        title: '确认',
        message: '确认退出当前账号?',
        cancel: true,
        persistent: true
      }).onOk(() => {
        removeToken();
        this.$disconnect();
        this.$router.push({
          path: '/login'
        })
      })
    },
    uploadPlayer() {
      if (null == this.player.steamId) {
        return
      }
      uploadProfile().then((res) => {
        Dialog.create({
          title: '数据已同步',
          message: '你现在可以看到新的头像和昵称!',
          cancel: false,
          persistent: false
        }).onOk(() => {
        })
      })
    },
  }
}
</script>


<style>
@import url('https://fonts.googleapis.com/css?family=Exo:400,700');

body {
  font-family: 'Exo', sans-serif;
}
</style>
